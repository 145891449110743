/* eslint-disable no-use-before-define */
import React, {
  forwardRef, useState, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import { handleCleanTitle, handleCleanPath, handleCustomizeName } from '../../../../../utils/utils';
import { Context } from '../../../../../store/Context';
import { Container, Button, Figure } from '../../../../components';
import {
  StyledMegaMenu,
  StyledMegaMenuWrapper,
  StyledButtonsWrapper,
  StyledMegaMenuContentWrapper,
  StyledMegaMenuContent,
} from './MegaMenu.styled';

const MegaMenu = forwardRef(({ isVisible }, megaMenuRef) => {
  const [selected, setSelected] = useState('banery');
  const [, dispatch] = useContext(Context);
  const data = useStaticQuery(query);

  const {
    banery, kasetony, katalogi, scianki, rollupy, gadzety,
  } = data;

  const productsList = [
    {
      category: 'banery',
      dataQuery: banery,
    },
    {
      category: 'kasetony',
      dataQuery: kasetony,
    },
    {
      category: 'katalogi',
      dataQuery: katalogi,
    },
    {
      category: 'ścianki',
      dataQuery: scianki,
    },
    {
      category: 'rollupy',
      dataQuery: rollupy,
    },
    {
      category: 'gadżety',
      dataQuery: gadzety,
    },
  ];

  const handleSelectButton = (id) => {
    setSelected(id);
  };

  const handleProductLink = (categoryPath, productPath) => {
    dispatch({ type: 'MEGAMENU_HIDE' });
    dispatch({ type: 'OVERLAY_HIDE' });
    const route = productPath.includes('katalogi') ? handleCleanPath(`/${productPath}`) : handleCleanPath(`/${categoryPath}/${productPath}`);
    return navigate(route);
  };

  return (
    <StyledMegaMenu ref={megaMenuRef} isVisible={isVisible}>
      <Container>
        <StyledMegaMenuWrapper>
          <StyledButtonsWrapper>
            {productsList.map(({ category }) => (
              <Button
                megaMenu
                key={category}
                title={category}
                arrowActive={category === selected}
                onClick={() => handleSelectButton(category)}
              />
            ))}
          </StyledButtonsWrapper>
          <StyledMegaMenuContentWrapper>
            {productsList.map(({ category, dataQuery }) => (
              <StyledMegaMenuContent key={category} active={category === selected}>
                {category && (
                  dataQuery.edges.map(({ node }) => (
                    <Figure
                      key={node.id}
                      smaller={category === 'rollupy'}
                      alt={handleCleanTitle(node.name)}
                      title={handleCustomizeName(node.name)}
                      image={node.childImageSharp.gatsbyImageData}
                      onClick={() => (category === 'gadżety'
                        ? window.open('https://banerbunny.inprimo.eu/', '_blank')
                        : handleProductLink(category, node.name))}
                    />
                  ))
                )}
              </StyledMegaMenuContent>
            ))}
          </StyledMegaMenuContentWrapper>
        </StyledMegaMenuWrapper>
      </Container>
    </StyledMegaMenu>
  );
});

MegaMenu.propTypes = {
  isVisible: PropTypes.bool,
};

MegaMenu.defaultProps = {
  isVisible: false,
};

export const query = graphql`
  query {
    banery: allFile(filter: {relativeDirectory: {eq: "icons/banery"}}, sort: {fields: name, order: [ASC]}) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    kasetony: allFile(filter: {relativeDirectory: {eq: "icons/kasetony"}}, sort: {fields: name, order: [ASC]}) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    katalogi: allFile(filter: {relativeDirectory: {eq: "icons/katalogi"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    scianki: allFile(filter: {relativeDirectory: {eq: "icons/scianki"}}, sort: { fields: name, order: [DESC] }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    rollupy: allFile(filter: {relativeDirectory: {eq: "icons/rollupy"}}, sort: { fields: id }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    gadzety: allFile(filter: {relativeDirectory: {eq: "icons/gadzety"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default MegaMenu;
